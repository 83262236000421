import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// import i18n (needs to be bundled ;))
import './i18n';

/*

import {withTolgee} from '@tolgee/i18next';
import i18n from "i18next";
import ICU from 'i18next-icu';
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

withTolgee(i18n, {
    apiUrl: 'https://i18n.tourinfra.com',
    apiKey: 'tgpak_gvpxm4ldnazhi3ljgftdmndngmzgsnbtgrwgw5rrgfyg4',
    ui: 'tgpak_gvpxm4ldnazhi3ljgftdmndngmzgsnbtgrwgw5rrgfyg4'
        ? require('@tolgee/ui').UI
        : undefined,
})
    .use(ICU)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        supportedLngs: ['cz','de','es','fr','en', 'pt'],
       fallbackLng: 'de'
    });
*/


const widgetDiv = document.getElementById('gs_wintermanager');
if (widgetDiv != null) {
    const root = createRoot(widgetDiv);
    let template = 'v1'
    let key = 'xpn2TiHry715xrqijkP2MAFKX5K4sdMibhOXb4OW'
    let hide_gallery = false
    let filter_loipengebiet = true
    let filter_skigebiet = true
    let filter_outdoor_areal = true
    let filter_only_open = false
    let always_expanded = false
    if (widgetDiv.dataset.hasOwnProperty('template')) {
        template = widgetDiv.dataset.template;
        if (template === '') template = 'v1';
    }
    if (widgetDiv.dataset.hasOwnProperty('hide_gallery')) {
        hide_gallery = widgetDiv.dataset.hide_gallery;
        if (hide_gallery === '') hide_gallery = 'false';
    }
    if (widgetDiv.dataset.hasOwnProperty('filter_loipengebiet')) {
        filter_loipengebiet = widgetDiv.dataset.filter_loipengebiet;
        if (filter_loipengebiet === '') filter_loipengebiet = 'true';
    }
    if (widgetDiv.dataset.hasOwnProperty('filter_skigebiet')) {
        filter_skigebiet = widgetDiv.dataset.filter_skigebiet;
        if (filter_skigebiet === '') filter_skigebiet = 'true';
    }
    if (widgetDiv.dataset.hasOwnProperty('filter_outdoor_areal')) {
        filter_outdoor_areal = widgetDiv.dataset.filter_outdoor_areal;
        if (filter_outdoor_areal === '') filter_outdoor_areal = 'true';
    }
    if (widgetDiv.dataset.hasOwnProperty('filter_only_open')) {
        filter_only_open = widgetDiv.dataset.filter_only_open;
        if (filter_only_open === '') filter_only_open = 'false';
    }
    if (widgetDiv.dataset.hasOwnProperty('always_expanded')) {
        always_expanded = widgetDiv.dataset.always_expanded;
        if (always_expanded === '') always_expanded = 'false';
    }
    if (widgetDiv.dataset.hasOwnProperty('key')) {
        key = widgetDiv.dataset.key;
        if (key === '') key = 'xpn2TiHry715xrqijkP2MAFKX5K4sdMibhOXb4OW';
    }
    console.log('index.js');
    root.render(
        <React.StrictMode>
            <App gebiet={widgetDiv.dataset.gebiet} template={template} showTopRow='true'
                 hide_gallery={widgetDiv.dataset.hide_gallery}
                 filter_loipengebiet={widgetDiv.dataset.filter_loipengebiet}
                 filter_skigebiet={widgetDiv.dataset.filter_skigebiet}
                 filter_outdoor_areal={widgetDiv.dataset.filter_outdoor_areal}
                 filter_only_open={widgetDiv.dataset.filter_only_open}
                 always_expanded={widgetDiv.dataset.always_expanded}
            />
        </React.StrictMode>
    );
} else {
    const container = document.getElementById('root');
    const root = createRoot(container);
    console.log('app init');
    root.render(
        <React.StrictMode>
            <App gebiet='233'
                 template='v2'
                 key='xpn2TiHry715xrqijkP2MAFKX5K4sdMibhOXb4OW'
                 showTopRow='true'
                 hide_gallery='false'
                 filter_loipengebiet='true'
                 filter_skigebiet='true'
                 filter_outdoor_areal='true'
                 filter_only_open='false'
                 always_expanded='false'/>
        </React.StrictMode>
    );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
