import React, {Suspense} from 'react';
import {Loader} from "./app/Loader";


import loadable from '@loadable/component';
const AppController = loadable(props => import(`./${props.template}/AppController`), {
    cacheKey: props => props.template,
});

function App(props) {
    let template = props.template;
    if (template==='bayerischer_wald') template='v2';
    console.log('app.js');
    //const AppController = React.lazy(() => import('./' + template + '/AppController'));
    return (<Suspense fallback={<Loader/>}><AppController gebiet={props.gebiet} template={template}
                                                          showTopRow={props.showTopRow}
                                                          key={props.key}
                                                          hide_gallery={eval(props.hide_gallery)}
                                                          filter_loipengebiet={eval(props.filter_loipengebiet)}
                                                          filter_skigebiet={eval(props.filter_skigebiet)}
                                                          filter_outdoor_areal={eval(props.filter_outdoor_areal)}
                                                          filter_only_open={eval(props.filter_only_open)}
                                                          always_expanded={eval(props.always_expanded)}
    /></Suspense>)
}

export default App;
