import React from "react";
import {useTranslation} from "react-i18next";
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

export const Loader = () => {
    const {t, i18n} = useTranslation();
    return (
        <Container>
            <Spinner animation="border" size="sm" role="status">
                <span className="visually-hidden">{t('global.loading')}</span>
            </Spinner>
        </Container>
    );
};
